import React, { useState, useEffect } from "react";
import { baseurl } from "../App";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Whatwedo = ({ currentLang }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [whatwedo_data, setwhatwedo_data] = useState({});
  const [service_data, setservice_data] = useState([]);
  const [loading, setloading] = useState(false);
  const [service_first, setService_first] = useState({});
  const [activeId, setActiveId] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    Whatwedo();
    FetchPost();
  }, [id]);

  const FetchPost = async () => {
    try {
      const response = await axios.get(`/admin/serviceById/${id}`);
      setwhatwedo_data(response.data.services);
      setloading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const changeWhatWeDo = (wid) => {
    setActiveId(wid);
    navigate(`/whatwedo/${wid}`);
    scrollToContent();
  };

  const scrollToContent = () => {
    const contentSection = document.getElementById("content1");
    if (contentSection) {
      contentSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const Whatwedo = async () => {
    try {
      const response = await axios.get("/admin/getactiveservice");
      setservice_data(response.data.services);
      setService_first(response.data.services[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getTitleById = (id) => {
    if (currentLang === "en") {
      switch (id) {
        case "1":
          return "Ceremonies, Shows, & Celebrations";
        case "2":
          return "Large-scale & Experiential Events";
        case "3":
          return "Event Consultation";
        default:
          return "Service Details";
      }
    } else {
      switch (id) {
        case "1":
          return "حفلات وعروض واحتفالات";
        case "2":
          return "الفعاليات الكبيرة والتجارب التفاعلية";
        case "3":
          return "استشارات الفعاليات";
        default:
          return "تفاصيل الخدمة";
      }
    }
  };

  const getTextById = (id) => {
    if (currentLang === "en") {
      switch (id) {
        case "1":
          return "Over the years, we have perfected the art of conceiving, creating, developing, and producing exceptional ceremonies, shows, and celebrations. Collaborating with some of the most talented creative minds in the industry, we approach each project with the belief that almost anything is possible. We encourage innovative thinking and strive to achieve the technical excellence necessary to bring our visions to life. Our focus remains on the audience, as every ceremony, show, and celebration is designed with them in mind. We honor attendees by crafting kaleidoscopic moments where music, light, performances, and special effects collide to create unforgettable experiences.";
        case "2":
          return "Bringing people together is at the heart of what we do. We understand that a strong concept is essential for creating impactful events, including festivals, fan zones, temporary destinations, and pop-up attractions. Our expertise encompasses a wide range of cultural, entertainment, and sports-related gatherings, where we conceptualize, master plan, design, activate and operate engaging environments. This is where our true magic shines brightest: we immerse ourselves in the event's culture, traditions, and rituals to capture its essence. By doing so, we create vivid moments and dynamic spaces that invite the community to come together and celebrate.";
        case "3":
          return "Our event consultation services go beyond the spectacle of events, positioning us as a strategic partner that offers insightful strategies and creative solutions. We expertly navigate the complexities of event planning and execution through a blend of knowledge, innovation, and precision, ensuring success in every project we undertake. Our consultancy services extend our event management capabilities, providing a holistic approach to creating memorable experiences. It includes expert advice and tailored solutions for businesses and organizations aiming to create, manage, and execute events effectively. This encompasses everything from strategy development and conceptualization to financial planning and technical production support. Let us help you strategize the best approach to bring your vision to life.";
        default:
          return "";
      }
    } else {
      switch (id) {
        case "1":
          return "أتقنّا فن ابتكار وتطوير وإنتاج احتفالات وعروض وحفلات استثنائية على مر السنين. بالتعاون مع بعض من ألمع العقول الإبداعية في هذا المجال، نبدء كل مشروع بإيمان بأن كل شيء ممكن. نشجع الابتكار ونسعى لتحقيق التميز الفني اللازم لتحويل الرؤية إلى واقع. تركيزنا الدائم هو على الجمهور، حيث نُصمم كل احتفال وعرض وفعالية مع مراعاة تفاصيل تجربتهم وخلق لحظات متألقة تتلاقى فيها الموسيقى والأضواء والعروض والمؤثرات الخاصة، لتقديم تجارب مميزة.";
        case "2":
          return "لأن جمع الناس هو جوهر ما نقوم به، فنحن ندرك أن قوة القصة هي أساس إنشاء فعاليات مؤثرة من المهرجانات، والوجهات المؤقتة، والأنشطة التفاعلية. تمتد خبراتنا لتشمل مجموعة واسعة من الفعاليات الثقافية والترفيهية والرياضية، حيث نصمم ونفعّل وندير بيئات جاذبة ومشوقة. هنا يتجلى سحرنا الحقيقي؛ فنحن نتعمق في الثقافة والتقاليد لنعكس الجوهر. وبهذا، نخلق لحظات حية ومساحات مميزة تدعو للتجمع والاحتفال.";
        case "3":
          return "خدمة الاستشارات لدينا تتجاوز مجرد تقديم فعاليات مذهلة، حيث نعمل كشريك استراتيجي يقدم استراتيجيات مدروسة وحلولًا إبداعية. بمزيج من الخبرة والابتكار والدقة نتقن التعامل مع تعقيدات تخطيط وتنفيذ الفعاليات لضمان نجاح كل مشروع نقوم به. تمتد خدمة الاستشارات لدينا لتشمل قدراتنا في إدارة الفعاليات، مما يتيح نهجًا شاملاً لخلق تجارب لا تُنسى. نقدم نصائح متخصصة وحلولاً للشركات والمؤسسات التي تهدف إلى إنشاء وإدارة وتنفيذ فعاليات باحترافية. يشمل ذلك كل شيء من تطوير الاستراتيجيات وتشكيل الأفكار إلى التخطيط المالي ودعم الإنتاج الفني. دعونا نساعدكم في تحديد أفضل الاستراتيجيات لتحقيق رؤيتك وجعلها واقع.";
        default:
          return "";
      }
    }
  };

  return (
    <div className="whatwedo">
      <section className="banner_chaild">
        <div
          className="container"
          style={{
            marginRight: 0,
            marginLeft: 0,
            width: "100%",
            padding: 0,
            maxWidth: "100%",
          }}
        >
          <div
            className="dd-titlediv container"
            style={{ marginBottom: "40px" }}
          >
            <h1
              className={
                currentLang === "en"
                  ? "title_head_1 pd20 wwe-title"
                  : "title_head_1_ar pd20 wwe-title"
              }
            >
              {t("What we do")}
            </h1>
            <h1 className="tpdb-title wwe-title">{t("What we do")}</h1>
          </div>
        </div>
      </section>

      <section>
        <div className="row">
          {service_data.slice(0, 3).map((item, index) => {
            let title;
            if (currentLang === "en") {
              if (index === 0) title = "Ceremonies, Shows, & Celebrations";
              else if (index === 1) title = "Large-scale & Experiential Events";
              else if (index === 2) title = "Event Consultation";
              else title = item.title;
            } else {
              // Arabic titles based on index
              if (index === 0) title = "حفلات وعروض واحتفالات";
              else if (index === 1)
                title = "الفعاليات الكبيرة والتجارب التفاعلية";
              else if (index === 2) title = "استشارات الفعاليات";
              else title = item.title_ar; // Assuming 'title_ar' is the Arabic title in the service data
            }

            return (
              <div
                className="item col-md-4 col-6 no-gutters whatwedo-items"
                key={item.id}
              >
                <div
                  className={`whatwe_mains what_wetext whatwedo_divdiv ${
                    activeId === item.id ? "active" : ""
                  }`}
                  id={item.id}
                  onClick={() => changeWhatWeDo(item.id)}
                >
                  <img
                    alt=""
                    src={`${baseurl}${item.image}`}
                    style={{ height: "auto", width: "100%" }}
                  />
                  <p className="whatwe_title wwe-title">{title}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="text-center" id="content1">
        {!loading ? (
          <div className="background_service">
            <img alt="" src={`${baseurl}${service_first.image}`} width="100%" />
            <div className="container service_child">
              <div className="row whatwedo_mains">
                <div className="col-md-6" style={{ position: "relative" }}>
                  <h1 className="smallwhite-title whatwedo-bigtitle wwe-title">
                    {currentLang === "en"
                      ? service_first.title
                      : service_first.title_ar}
                  </h1>
                </div>
                <div className="col-md-6">
                  <p className="service_texts service_text_p wwe-title">
                    {currentLang === "en"
                      ? service_first.body
                      : service_first.body_ar}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="background_service">
            <img alt="" src={`${baseurl}${whatwedo_data.image}`} width="100%" />
            <div className="container service_child">
              <div className="row whatwedo_mains">
                <div className="col-md-6" style={{ position: "relative" }}>
                  <h1 className="smallwhite-title whatwedo-bigtitle wwe-title">
                    {currentLang === "en" ? getTitleById(id) : getTitleById(id)}
                  </h1>
                </div>
                <div className="col-md-6">
                  <p className="service_texts service_text_p wwe-title">
                    {currentLang === "en" ? getTextById(id) : getTextById(id)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Whatwedo;
